import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import coverStyles from "./cover.module.scss"
import SEO from "../components/seo.js"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        published_date
      }
      html
    }
  }
`

const Cover = props => {
  return (
    <Layout>
      <SEO title={props.data.markdownRemark.frontmatter.title + " | Notion Cover"}/>
      <div className={coverStyles.content}>
        <h2 className={coverStyles.title}>{props.data.markdownRemark.frontmatter.title}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}
        ></div>
      </div>
      <Link className="button" to="/">Back</Link>
    </Layout>
  )
}

export default Cover
